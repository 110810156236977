/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://govkxnomargh7oderahvytehkq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rcjnlvvnazbypnex56mkaewwia",
    "aws_cognito_identity_pool_id": "us-west-2:89cead0d-cb9f-4e2c-bfcc-8ebfe137d04c",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_vyZw2kxWP",
    "aws_user_pools_web_client_id": "lqsmipao62tec0lcdbgvsq4uk",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "joshmk-bucket180454-btbackend",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
